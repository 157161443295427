<template>
    <section class="content">
        <div class="card">
            <div class="card-body">
                <h4>Info barang</h4>
                <div class="row">
                    <div class="col-sm-4">
                        <table class="table table-borderless">
                            <tr>
                                <td width="20%">Code</td>
                                <td width="20%">:</td>
                                <td class="code">{{info.kode_barang}}</td>
                            </tr>
                            <tr>
                                <td width="20%">Nama</td>
                                <td width="20%">:</td>
                                <td class="code">{{info.name}}</td>
                            </tr>
                            <tr>
                                <td width="20%">Brand</td>
                                <td width="20%">:</td>
                                <td class="code">{{info.brand}}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-sm-4">
                        <table class="table table-borderless">
                            <tr>
                                <td width="20%">Dimensi</td>
                                <td width="20%">:</td>
                                <td class="code">{{info.dimensi}}</td>
                            </tr>
                            <tr>
                                <td width="20%">Deskripsi</td>
                                <td width="20%">:</td>
                                <td class="code">{{info.keterangan}}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-sm-4">
                        <img style="object-fit: contain; height: 120px; width: 100%" alt="image" id="imgPreview" class="img-fluid img-responsive" :src="url + '/uploads/default/no_image.png'" />
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsivex">
            <table class="table table-hover" ref="tableuser" style="width: 100%;">
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Tanggal</th>
                        <th>Qty Masuk</th>
                        <th>Qty Keluar</th>
                        <th>Keterangan</th>
                        <th>Harga Beli</th>
                    </tr>
                </thead>
                <tbody @click="handleClick">
                </tbody>
            </table>
        </div>
    </section>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import datepicker from '@/components/Datepicker';

export default {
    name: 'InventarisDetail',
    components: {
        vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            formTitle: 'Tambah',
            genders: [
                {id: 'L', label: 'Laki-laki'},
                {id: 'P', label: 'Perempuan'}
            ],
            optMapel: [],
            info: [],
            form: {
                name: '',
                addr_detail: '',
                no_hp: '',
                email: '',
                gender: '',
                birth_date: '',
                mapel_id: '',
                img_code: ''
            },
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)
        this.url = window.$apiUrl;
        this.loadData(this.$route.params.id)
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
            if (e.srcElement.getAttribute('class') == 'btn btn-primary') {
                this.$router.push('/inventaris-detail/'+e.srcElement.getAttribute('data-id'));
            }
        },
        loadData: function(id) {
            authFetch('/management/inventaris/info/'+id)
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.info = js.data;
                if (this.info.img_code) {
                    $("#imgPreview").attr(
                        "src", 
                        window.$apiUrl+"/uploads/" + this.info.file_path + "/" + this.info.file_name
                    );
                }
            })
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            "title": "Inventaris",
            "roles": this.$route.params.roles,
            "ajax": "/management/inventaris/history",
            scrollX: true,
            "columns": [
                { "data": "transaksi_no" },
                { "data": "transaksi_tanggal" },
                { "data": "qty_masuk" },
                { "data": "qty_keluar" },
                { "data": "keterangan" },
                { "data": "harga_beli" },
            ],
            filterBy: [0, 1, 2],
            "rowCallback": function(row, data) {
                // $('td:eq(5)', row).html('<a type="button" class="btn btn-primary" data-id="'+data.id+'">Detail</a>');
            },
            buttonClick: (evt) => { 
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Data Guru';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Data Guru';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    authFetch('/akademik/data_guru/' + evt.data.id, {
                            method: 'DELETE',
                            body: 'id=' + evt.data.id
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            this.table.api().ajax.reload();
                        });
                }

            },
        });

    }
}
</script>